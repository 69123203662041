import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('./pages/home')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./pages/login')
        },
        {
            path: '/courseList',
            name: 'courseList',
            component: () => import('./pages/courseList')
        },
        {
            path: '/courseDetail',
            name: 'courseDetail',
            component: () => import('./pages/courseDetail')
        },
        {
            path: '/videoPlay',
            name: 'videoPlay',
            component: () => import('./pages/videoPlay')
        },
        {
            path: '/xwHome',
            name: 'xwHome',
            component: () => import('./pages/xiaowenTeacher/home')
        },
        {
            path: '/download',
            name: 'download',
            component: () => import('./pages/download')
        },
        {
            path: '/questionBank',
            name: 'questionBank',
            component: () => import('./pages/questionBank')
        },
        {
            path: '/questionDetail',
            name: 'questionDetail',
            component: () => import('./pages/questionDetail')
        },
        {
            path: '/putQuestion',
            name: 'putQuestion',
            component: () => import('./pages/xiaowenTeacher/putQuestion')
        },
        {
            path: '/myQuestion',
            name: 'myQuestion',
            component: () => import('./pages/xiaowenTeacher/myQuestion')
        },
        {
            path: '/xwQuestionDetail',
            name: 'xwQuestionDetail',
            component: () => import('./pages/xiaowenTeacher/xwQuestionDetail')
        },
        {
            path:'/xwContentList',
            name:'xwContentList',
            component:()=>import('./pages/xiaowenTeacher/xwContentList')
        },
        {
            path:'/xwContentDetail',
            name:'xwContentDetail',
            component:()=>import('./pages/xiaowenTeacher/xwContentDetail')
        },
        {
            path:'/allWenList',
            name:'allWenList',
            component:()=>import('./pages/xiaowenTeacher/allWenList')
        },
        {
            path:'/allWenDetail',
            name:'allWenDetail',
            component:()=>import('./pages/xiaowenTeacher/allWenDetail')
        }
    ]
})