import Vue from 'vue'
import axios from 'axios'
import ElementUI, { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './css/global.less';
Vue.use(ElementUI);
import App from './App.vue'
import router from './router'
import Video from 'video.js'
import 'video.js/dist/video-js.css'

// axios.defaults.baseURL = 'https://www.kaixinliankao.com/education-api';
axios.defaults.baseURL = '/education-api';
// axios.defaults.baseURL = 'http://192.168.1.6:8088/education-api';
// axios.defaults.baseURL = 'http://localhost:8080/education-api';
axios.defaults.withCredentials = true;
//请求后拦截
axios.interceptors.response.use(function (response) {
  let res = response.data;
  if (res.code == 200) {
    return res;
  } else if (res.code == 401) {
    // window.location.href = '/#/login';
    return Promise.reject(res);
  } else {
    Message.warning(res.msg);
    return Promise.reject(res);
  }
}, (error) => {
  let res = error.response;
  Message.error(res.data.message);
  return Promise.reject(error);
});

Vue.prototype.axios = axios
Vue.prototype.$video = Video
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
